import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { 
  Container, 
  NewsHeader, 
  NewsContent, 
  CarouselContainer, 
  CarouselImage, 
  ArrowButton 
} from "./styles"; 
import newsImage1 from "../../assets/News1.png";
import newsImage2 from "../../assets/News2.png";
import newsImage3 from "../../assets/News5.png";
import newsImage4 from "../../assets/News4.png";
import newsImage5 from "../../assets/News3.png";
import newsImage6 from "../../assets/News6.png";
import newsImage7 from "../../assets/News7.png";


export function News() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [newsImage1, newsImage2, newsImage3, newsImage4, newsImage5,newsImage6,newsImage7];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const autoplay = setInterval(nextSlide, 6000);
    return () => clearInterval(autoplay);
  }, []);

  return (
    <Container>
      <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
        <NewsHeader>Latest News</NewsHeader>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
        <CarouselContainer>
          <ArrowButton className="prev" onClick={prevSlide}>
            &#10094;
          </ArrowButton>
          <CarouselImage
            src={images[currentIndex]}
            alt={`News ${currentIndex + 1}`}
          />
          <ArrowButton className="next" onClick={nextSlide}>
            &#10095;
          </ArrowButton>
        </CarouselContainer>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInUp" duration={1.5} delay={0.5}>
        <NewsContent>
          <p>Here is some interesting news related to our website...</p>
        </NewsContent>
      </ScrollAnimation>
    </Container>
  );
}
